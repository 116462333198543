<template>
  <div class="home bg-gray pb-5 d-flex align-content-center justify-content-center flex-wrap">
    <div class="loader d-flex align-items-center" v-if="loading">
      <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-5">
          <div class="row">
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-10 no-padding">
                  <el-input
                    placeholder="Paste your URL here..."
                    v-model="url"
                  />
                </div>
                <div class="col-2 pl-2">
                  <b-button variant="purple" @click="createUrl"
                    >Create Url</b-button
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <p>Your shortened link:</p>
            </div>
            <div class="col-12" v-if="shortUrl">
              <code>{{ shortUrl }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { db, timestamp } from '@/plugins/firebase'
  const urlDb = db.collection('urls')

  export default {
    name: 'Home',
    data() {
      return {
        url: '',
        shortUrl: '',
        loading: false
      }
    },
    mounted() {
      this.loading = true
      this.hash = this.$route.params.hash
      if (this.hash) {
        this.getUrl(this.hash)
      }
      this.loading = false
    },
    methods: {
      async createUrl() {
        this.loading = true
        let chars = 'abcdefghijklmnopqrstuvwxyz1234567890'
        let str = ''
        while (str.length < 8)
          str += chars.charAt(Math.floor(Math.random() * chars.length))
        const uniqueID = str
        await urlDb.add({
          shortUrl: `https://shorturl.digammastudio.com/${uniqueID}`,
          url: this.url,
          hash: uniqueID,
          date: timestamp.now()
        })
        this.loading = false
        this.shortUrl = `https://shorturl.digammastudio.com/${uniqueID}`
      },

      async getUrl(hash) {
        let url = []
        await urlDb
          .where('hash', '==', hash)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              url = doc.data()
              window.location.href = url.url
            })
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      }
    }
  }
</script>
