<template>
  <div id="app">
    <Navbar logo="https://storage.googleapis.com/cutkl-801c9.appspot.com/Logo-Color.png" />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
export default {
  components: {
    Navbar,
    Footer
  },
}
</script>