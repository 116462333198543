<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="transparent">
      <div class="container">
        <!-- <b-navbar-brand href="#"><img src="@/assets/images/digammastudio-cutkl.png" width="150"/></b-navbar-brand> -->
        <!-- <b-navbar-brand href="#"><img src="@/assets/images/logo-memorias.jpg" width="150"/></b-navbar-brand> -->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto py-2">
          <img src="@/assets/images/digammastudio-cutkl.png" width="220"/>
        </b-navbar-nav>
      </div>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    }
  },
  props: ["logo"],
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
  },
}
</script>
